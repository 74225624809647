import * as React from 'react';
import { Container, Flex, Spacer } from '@chakra-ui/react';
import { IBaseComponentProps } from '@/common/types/component.types';
import LOGO from '@/components/logo/Logo.component';
import NAVIGATION from '@/components/navigation/Nav.component';

function HEADER(props: IBaseComponentProps): JSX.Element {
  return (
    <Container
      maxWidth="full"
      p={0}
      backgroundColor="brand.dark"
      borderBottom="4px solid #a89b80"
    >
      <Container maxWidth="container.xl" p={0}>
        <Flex>
          <LOGO />
          <Spacer />
          <NAVIGATION {...props} />
        </Flex>
      </Container>
    </Container>
  );
}

export default HEADER;
