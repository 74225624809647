import { Box, chakra, Text } from '@chakra-ui/react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = chakra('footer', {
  label: 'bfs-footer',
  baseStyle: {
    backgroundColor: 'brand.gray',
    borderRadius: 0,
    borderTop: '4px solid #a89b80',
    color: 'brand.lightTan',
    paddingTop: '22px',
    textTransform: 'uppercase',
  },
});

const SubFooter = chakra(Box, {
  label: 'bfs-sub-footer',
  baseStyle: {
    backgroundColor: 'brand.dark',
    padding: '24px 0',
    height: '100%',
  },
});

const TagLine = chakra('p', {
  label: 'bfs-tag-line',
  baseStyle: {
    color: 'brand.tan',
    fontSize: '14px',
    fontStyle: 'italic',
    margin: '8px auto 28px',
    textAlign: 'center',
  },
});

const ChakraOutboundLink = chakra(OutboundLink, {
  label: 'bfs-outbound-link',
  baseStyle: {
    color: 'brand.tan',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    margin: '0 8px',
    _hover: {
      color: 'brand.lightTan',
      cursor: 'pointer',
    },
  },
});

const FooterSpan = chakra(Text, {
  label: 'bfs-footer-span',
  baseStyle: {
    color: 'brand.lightTan',
    marginTop: '3px',
    display: 'block',
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '12px',
  },
});

const EmailIcon = chakra(FontAwesomeIcon, {
  label: 'bfs-email-icon',
  baseStyle: {
    color: 'brand.tan',
    marginRight: 0,
    width: '22px',
    height: '30px',
    fontSize: '1.5rem',
    _hover: {
      color: 'brand.lightTan',
    },
  },
});

export default {
  Footer,
  SubFooter,
  TagLine,
  ChakraOutboundLink,
  FooterSpan,
  EmailIcon,
};
