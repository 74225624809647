import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/state-management/store';

export interface IUIState {
  isDrawerOpen: boolean;
}

export interface IDrawerStatePayload {
  isOpen: boolean;
}

const initialState: IUIState = {
  isDrawerOpen: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setDrawerState: (state, action: PayloadAction<IDrawerStatePayload>) => {
      state.isDrawerOpen = action.payload.isOpen;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.uiSlice.value;

export const { setDrawerState } = uiSlice.actions;
export default uiSlice.reducer;
