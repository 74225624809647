import { chakra, Link } from '@chakra-ui/react';

const LogoButton = chakra(Link, {
  label: 'logo-button',
  baseStyle: {
    appearance: 'none',
    _hover: {
      appearance: 'none',
      textDecoration: 'none',
    },
  },
});

export default { LogoButton };
