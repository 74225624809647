import * as React from 'react';
import { Box, HStack, Text, useColorMode } from '@chakra-ui/react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useAppDispatch, useAppSelector } from '@/state-management/hooks';
import { setDrawerState } from '@/state-management/slices/ui/ui.slice';
import { RootState } from '@/state-management/store';
import { IBaseComponentProps } from '@/common/types/component.types';
import { BFS_ClickEvent } from '@/common/types/override.types';
import NAV_BUTTON from '@/components/navigation/NavButton/NavButton.component';

const getHamburgerClassName = (drawerOpen: boolean): string => {
  if (drawerOpen) {
    return 'hamburger hamburger--emphatic is-active';
  }
  return 'hamburger hamburger--emphatic';
};

function NAVIGATION(props: IBaseComponentProps): JSX.Element {
  const dispatch = useAppDispatch();

  // const sendDataToGTM = useGTMDispatch();
  // const { toggleColorMode, colorMode } = useColorMode();

  // App State
  const { isDrawerOpen } = useAppSelector((state: RootState) => state.ui);

  return (
    <>
      <HStack
        p={0}
        spacing={3}
        display={{ base: 'none', md: 'flex' }}
        alignContent="center"
      >
        <NAV_BUTTON to="/" {...props}>
          Home
        </NAV_BUTTON>
        <Text color="brand.lightTan">|</Text>
        <NAV_BUTTON to="/blog/" {...props}>
          Blog
        </NAV_BUTTON>
        <Text color="brand.lightTan">|</Text>
        <NAV_BUTTON to="/about/" {...props}>
          About
        </NAV_BUTTON>
        <Text color="brand.lightTan">|</Text>
        <NAV_BUTTON to="/services/" {...props}>
          Services
        </NAV_BUTTON>
        <Text color="brand.lightTan">|</Text>
        <NAV_BUTTON to="/contact/" {...props}>
          Contact
        </NAV_BUTTON>
        {/* <NavStyles.DarkModeSwitch
          colorScheme="brand"
          size="sm"
          spacing="1"
          value={colorMode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            sendDataToGTM({
              event: 'toggle-dark-mode',
              customData: `Previous Value: ${colorMode}`,
            });
            toggleColorMode();
          }}
        >
          Toggle Dark Mode
        </NavStyles.DarkModeSwitch> */}
      </HStack>
      <Box
        display={{ base: 'block', md: 'none' }}
        alignSelf="center"
        paddingRight="14px"
      >
        <button
          className={getHamburgerClassName(isDrawerOpen)}
          type="button"
          onClick={(event: BFS_ClickEvent) => {
            event.preventDefault();
            dispatch(setDrawerState({ isOpen: !isDrawerOpen }));
          }}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </Box>
    </>
  );
}

export default NAVIGATION;
