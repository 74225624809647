import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { INavButtonProps } from '@/components/navigation/Nav.types';
import NavStyles from '@/components/navigation/Nav.styles';

function STACK_NAV_BUTTON(props: INavButtonProps): JSX.Element {
  const { children, to } = props;

  return (
    <NavStyles.StackNavButton as={GatsbyLink} to={to}>
      {children}
    </NavStyles.StackNavButton>
  );
}

export default STACK_NAV_BUTTON;
