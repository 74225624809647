import FooterStyles from '@/components/footer/Footer.styles';
import GATSBY_LOGO from '@/components/logo/GatsbyLogo.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, chakra, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { navigate } from 'gatsby';

import {
  faAngular,
  faGithub,
  faGitlab,
  faInstagram,
  faLinkedin,
  faReact,
  faStackOverflow,
  faTwitter,
  faVuejs,
} from '@fortawesome/free-brands-svg-icons';

import {
  faEnvelopeOpenText,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';

function FOOTER(): JSX.Element {
  const ChakraLogo = chakra(GATSBY_LOGO);
  return (
    <FooterStyles.Footer as="footer">
      {/* FOOTER CONTENT CONTAINER */}
      <Box margin="50px auto" maxWidth="460px">
        {/* LOGO CONTAINER */}
        <Box margin="0 auto" width={['320px', '460px']}>
          <Box maxWidth="125px" margin="0 auto">
            <ChakraLogo />
          </Box>
          <FooterStyles.TagLine>
            <Text as="b">
              Fully Custom -- Never Wordpress -- Never Templated
            </Text>
            <Text width="80%" margin="4px auto" as="span" display="block">
              We utilize the latest bleeding edge technology, tooling, design
              principles, and methodologies in the modern digital landscape.
            </Text>
          </FooterStyles.TagLine>
        </Box>
        <Flex marginLeft={['12px', 0]} marginRight={['12px', 0]}>
          {/* TECH LIST CONTAINER */}
          <Box>
            <Heading
              as="h4"
              size="sm"
              color="brand.lightTan"
              textAlign="center"
            >
              Technology
            </Heading>
            <FooterStyles.ChakraOutboundLink
              href="https://reactjs.org/"
              color="brand.tan"
            >
              React.js
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink href="https://angular.io/">
              Angular.io
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink href="https://vuejs.org/">
              Vue.js
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink href="https://graphql.org/">
              GraphQL
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.FooterSpan as="span">
              plus many more...
            </FooterStyles.FooterSpan>
          </Box>
          <Spacer width="100%" />
          {/* TOOLING CONTAINER */}
          <Box>
            <Heading
              as="h4"
              size="sm"
              color="brand.lightTan"
              textAlign="center"
            >
              Infrastructure
            </Heading>
            <FooterStyles.ChakraOutboundLink href="https://github.com/">
              GitHub
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink href="https://www.atlassian.com/">
              Atlassian Suite
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink href="https://www.netlify.com/">
              Netlify
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink href="https://aws.amazon.com/">
              AWS
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.FooterSpan as="span">
              plus many more...
            </FooterStyles.FooterSpan>
          </Box>
        </Flex>
      </Box>

      {/* SUB-FOOTER CONTAINER */}
      <FooterStyles.SubFooter>
        {/* SUB-CONTENT-CONTAINER */}
        <Flex
          justifyContent="space-between"
          height="100%"
          width={['100%', '80%']}
          margin={['0 12px', '0 auto']}
        >
          <Box alignSelf="center">
            <Heading
              as="h3"
              alignSelf="center"
              marginBottom="0"
              fontSize="1.17em"
            >
              COPYRIGHT © {new Date().getFullYear()},{' '}
              <Text color="brand.tan" display="inline">
                BEFREESTUDIOS.dev
              </Text>
            </Heading>{' '}
            <FooterStyles.FooterSpan as="span" textAlign="left">
              All Rights Reserved
            </FooterStyles.FooterSpan>
          </Box>
          <Box alignSelf="center">
            <FooterStyles.ChakraOutboundLink
              display="inline-block"
              onClick={() => {
                navigate('/contact');
              }}
            >
              <FooterStyles.EmailIcon
                icon={faEnvelopeOpenText}
                title="Email"
                size="2x"
              />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://twitter.com/BeFreeStudios"
              display="inline-block"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                title="Twitter"
                size="2x"
                width="22px"
                fontSize="1.5rem"
              />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://www.instagram.com/befreestudios/"
              display="inline-block"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                title="Instagram"
                size="2x"
                width="22px"
                fontSize="1.5rem"
              />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://www.linkedin.com/in/bepod/"
              display="inline-block"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                title="Linked In"
                size="2x"
                width="22px"
                fontSize="1.5rem"
              />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://github.com/bpod"
              display="inline-block"
            >
              <FontAwesomeIcon
                icon={faGithub}
                title="GitHub"
                size="2x"
                width="22px"
                fontSize="1.5rem"
              />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://gitlab.com/befreestudios"
              display="inline-block"
            >
              <FontAwesomeIcon
                icon={faGitlab}
                title="GitLab"
                size="2x"
                width="22px"
                fontSize="1.5rem"
              />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://stackoverflow.com/users/604256/be-free-studios"
              display="inline-block"
            >
              <FontAwesomeIcon
                icon={faStackOverflow}
                title="Stack Overflow"
                size="2x"
                width="22px"
                fontSize="1.5rem"
              />
            </FooterStyles.ChakraOutboundLink>

            {/*  */}
            <FontAwesomeIcon
              icon={faEllipsisV}
              size="lg"
              style={{ color: '#e38525', margin: '6px 12px' }}
            />
            {/*  */}

            <FooterStyles.ChakraOutboundLink
              href="https://angular.io/"
              display="inline-block"
            >
              <FontAwesomeIcon icon={faAngular} title="Angular.io" size="2x" />
            </FooterStyles.ChakraOutboundLink>

            <FooterStyles.ChakraOutboundLink
              href="https://reactjs.org/"
              display="inline-block"
            >
              <FontAwesomeIcon icon={faReact} title="React.JS" size="2x" />
            </FooterStyles.ChakraOutboundLink>
            <FooterStyles.ChakraOutboundLink
              href="https://vuejs.org/"
              display="inline-block"
            >
              <FontAwesomeIcon icon={faVuejs} title="Vue.JS" size="2x" />
            </FooterStyles.ChakraOutboundLink>
          </Box>
        </Flex>
      </FooterStyles.SubFooter>
    </FooterStyles.Footer>
  );
}

export default FOOTER;
