import { chakra, Link, Switch } from '@chakra-ui/react';

const NavButton = chakra(Link, {
  label: 'nav-button',
  baseStyle: {
    color: 'brand.lightTan',
    _hover: {
      color: 'brand.orange',
      textDecoration: 'underline',
    },
    _activeLink: {
      color: 'brand.orange',
      _hover: { textDecoration: 'none', cursor: 'inherit' },
    },
  },
});

const StackNavButton = chakra(Link, {
  label: 'stack-nav-button',
  baseStyle: {
    borderBottom: '1px solid #e1d4be',
    color: 'brand.lightTan',
    height: '40px',
    paddingTop: '8px',
    paddingLeft: '14px',
    _hover: {
      backgroundColor: 'brand.gray',
      borderLeft: '4px solid #e38525',
      color: '#fff',
      textDecoration: 'underline',
      paddingLeft: '10px',
    },
    _activeLink: {
      fontWeight: '600',
      backgroundColor: 'brand.gray',
      borderLeft: '4px solid #e38525',
      color: '#fff',
      paddingLeft: '10px',
      _hover: { textDecoration: 'none', cursor: 'inherit' },
    },
  },
});

const DarkModeSwitch = chakra(Switch, {
  label: 'dark-mode-switch',
  baseStyle: {
    color: 'brand.lightTan',
    display: 'flex',
    fontSize: '12px',
    _hover: {
      color: 'brand.orange',
      '.chakra-switch__track': {
        borderColor: 'brand.orange',
      },
      '.chakra-switch__thumb': {
        backgroundColor: 'brand.orange',
      },
    },
    '.chakra-switch__track': {
      backgroundColor: 'brand.gray',
      border: '1px solid #e1d4be',
    },
    '.chakra-switch__label': {
      _hover: {
        cursor: 'pointer',
      },
    },
    _checked: {
      outline: '0 none',
      '.chakra-switch__track': {
        backgroundColor: 'brand.orange',
        border: '1px solid #e1d4be',
      },
      '.chakra-switch__thumb': {
        backgroundColor: 'brand.gray',
      },
      '.chakra-switch__label': {
        color: 'brand.orange',
      },
    },
  },
});

const BlogFooterNav = chakra('nav', {
  label: 'bfs-blog-footer-nav',
  baseStyle: {
    fontWeight: 900,
    margin: '32px 0',
  },
});

export default { NavButton, StackNavButton, DarkModeSwitch, BlogFooterNav };
