import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { INavButtonProps } from '@/components/navigation/Nav.types';
import NavStyles from '@/components/navigation/Nav.styles';

function NAV_BUTTON(props: INavButtonProps): JSX.Element {
  const { children, to } = props;

  return (
    <NavStyles.NavButton as={GatsbyLink} to={to}>
      {children}
    </NavStyles.NavButton>
  );
}

export default NAV_BUTTON;
