/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Img from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
const GATSBY_LOGO = ({ siteTitle = '' }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logoFull.png" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.logo.childImageSharp.fluid} alt={siteTitle} />;
};

export default GATSBY_LOGO;
