import { extendTheme } from '@chakra-ui/react';
import NavStyles from '../../components/navigation/Nav.styles';

// type CustomTheme = typeof theme;

const theme = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  components: {
    NavButton: NavStyles.NavButton,
  },
  colors: {
    brand: {
      dark: '#2b2b2b',
      orange: '#e38525',
      gray: '#515152',
      blue: '#314e64',
      lightBlue: '#3486c4',
      tan: '#a89b80',
      lightTan: '#e1d4be',
    },
  },
  fonts: {
    heading: 'Fira Sans',
    body: 'Montserrat',
  },
  styles: {
    global: {
      'html, body': {
        color: 'brand.dark',
        backgroundColor: 'brand.dark',
      },
      '.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after': {
        backgroundColor: 'brand.lightTan',
      },
      '.hamburger.is-active': {
        '.hamburger-inner::before, .hamburger-inner::after': {
          backgroundColor: 'brand.lightTan',
        },
      },
      '.chakra-heading': {
        color: 'brand.gray',
      },
      '.chakra-slide': {
        backgroundColor: '#2b2b2b !important',
        color: '#e1d4be !important',
        top: '77px !important',
      },
      '.chakra-modal__overlay': {
        top: '77px !important',
      },
      '.chakra-modal__body': {
        padding: '0 !important',
      },
      '.chakra-modal__content-container': {
        top: '77px !important',
      },
      '.chakra-form__label': {
        fontWeight: '600 !important',
      },
      a: {
        color: 'brand.lightBlue',
        _hover: {
          color: 'brand.blue',
        },
      },
    },
  },
};

export default extendTheme(theme);
