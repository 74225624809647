import * as React from 'react';
import { HStack, VStack, Heading, Image, Text } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import LogoStyles from '@/components/logo/Logo.styles';
import logoFullRotated from '@/assets/images/logoFull-rotated.png';

function LOGO(): JSX.Element {
  return (
    <LogoStyles.LogoButton as={GatsbyLink} to="/" textDecoration="none">
      <HStack spacing={-4} p={0} paddingLeft="14px">
        <Image
          boxSize={{ base: '75px', md: '116px' }}
          objectFit="cover"
          src={logoFullRotated}
          alt="Be Free Studios"
          zIndex="100"
        />
        <VStack spacing={-1}>
          <Heading
            color="brand.gray"
            fontSize={{ base: '32px', md: '60px' }}
            marginLeft={{ base: '4px', md: '-6px' }}
            paddingRight="12px"
            background={{
              base: '-webkit-linear-gradient(0deg, #e38525 5%, #515152 91%); -webkit-background-clip: text; -webkit-text-stroke: 2px transparent',
              md: '-webkit-linear-gradient(0deg, #e38525 5%, #515152 91%); -webkit-background-clip: text; -webkit-text-stroke: 4px transparent',
            }}
          >
            FREESTUDIOS
          </Heading>
          <Text
            alignSelf="flex-end"
            color="brand.lightTan"
            paddingRight={3}
            fontSize={{ base: '12px', md: '16px' }}
          >
            design | development | strategy
          </Text>
        </VStack>
      </HStack>
    </LogoStyles.LogoButton>
  );
}

export default LOGO;
