import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

export interface IRootElementWrapper {
  element: any;
  resetCSS: boolean;
  portalZIndex: number;
}

export function WrapRootElement({
  element,
  resetCSS = true,
  portalZIndex = 40,
}: IRootElementWrapper): JSX.Element {
  return (
    <ChakraProvider
      theme={theme}
      resetCSS={resetCSS}
      portalZIndex={portalZIndex}
    >
      {element}
    </ChakraProvider>
  );
}
