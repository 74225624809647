import React from 'react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import createStore from '@/state-management/store';
import { Provider } from 'react-redux';
import BASE_LAYOUT from '@/components/layout/BaseLayout.component';

export interface LayoutWrapperProps {
  element?: any;
  props: any;
}

function wrapPageElement({ element, props }: LayoutWrapperProps): JSX.Element {
  const store = createStore();
  const gtmParams = { id: 'GTM-T8QVGNH' };
  return (
    <Provider store={store}>
      <GTMProvider state={gtmParams}>
        <BASE_LAYOUT {...props}>{element}</BASE_LAYOUT>
      </GTMProvider>
    </Provider>
  );
}

export default wrapPageElement;
