import * as React from 'react';
import {
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useAppSelector } from '@/state-management/hooks';
import { RootState } from '@/state-management/store';
import NAVIGATION_STACK from '@/components/navigation/NavStack.component';
import HEADER from '@/components/header/Header.component';
import { IBaseComponentProps } from '@/common/types/component.types';
import FOOTER from '@/components/footer/Footer.component';
import NavStyles from '../navigation/Nav.styles';

function BASE_LAYOUT(props: IBaseComponentProps): JSX.Element {
  const { children } = props;
  const { onOpen, onClose } = useDisclosure();

  //   const sendDataToGTM = useGTMDispatch();
  //   const { toggleColorMode, colorMode } = useColorMode();

  // App State
  const { ui } = useAppSelector((state: RootState) => state); // App State
  const { isDrawerOpen } = ui;

  useEffect(() => {
    if (isDrawerOpen) {
      onOpen();
    } else {
      onClose();
    }
  }, [isDrawerOpen]);

  return (
    <Container maxWidth="full" p={0}>
      <HEADER {...props} />
      {children}
      <FOOTER />

      {/* @TODO: Create Component with below */}
      <Drawer
        closeOnOverlayClick
        closeOnEsc
        placement="right"
        isOpen={isDrawerOpen}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <NAVIGATION_STACK {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  );
}

export default BASE_LAYOUT;
