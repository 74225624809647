import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { IBaseComponentProps } from '@/common/types/component.types';
import STACK_NAV_BUTTON from '@/components/navigation/NavButton/StackNavButton.component';

function NAVIGATION_STACK(props: IBaseComponentProps): JSX.Element {
  return (
    <Stack spacing={0}>
      <STACK_NAV_BUTTON to="/" {...props}>
        <Text>Home</Text>
      </STACK_NAV_BUTTON>
      <STACK_NAV_BUTTON to="/blog/" {...props}>
        <Text>Blog</Text>
      </STACK_NAV_BUTTON>
      <STACK_NAV_BUTTON to="/about/" {...props}>
        <Text>About</Text>
      </STACK_NAV_BUTTON>
      <STACK_NAV_BUTTON to="/services/" {...props}>
        <Text>Services</Text>
      </STACK_NAV_BUTTON>
      <STACK_NAV_BUTTON to="/contact/" {...props}>
        <Text>Contact</Text>
      </STACK_NAV_BUTTON>
    </Stack>
  );
}

export default NAVIGATION_STACK;
